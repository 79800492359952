<template>
  <div class="pa-6 login-bg">
    <v-row>
      <v-col cols="8" v-if="!isSmall"></v-col>
      <v-col class="login-form form-opacity d-flex flex-column justify-center px-6">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-alert v-if="loginErrorMessage" type="error" dismissible>
            {{ loginErrorMessage }}
          </v-alert>
          <v-text-field
            v-model="username"
            outlined
            :rules="[rules.required, rules.email]"
            :label="$t('labels.email')" />
          <v-text-field
            v-model="password"
            outlined
            :rules="[rules.required, rules.min]"
            :append-icon="showPass ? showPassIcon : hidePassIcon"
            :append-outer-icon="resetIcon"
            :type="showPass ? 'text' : 'password'"
            :label="$t('labels.password')"
            @click:append="showPass = !showPass"
            @click:append-outer="showResetDialog"
          />

          <v-btn color="primary" block @click="login" :loading="loading" :disabled="loading">{{ $t('labels.login') }}</v-btn>
        </v-form>
      </v-col>
    </v-row>
    <v-dialog v-model="isResetDialog" width="500">
      <v-card>
        <v-card-title>{{ $t('labels.password_reset') }}</v-card-title>
        <v-alert v-if="errorMessage" type="error">
          {{ errorMessage }}
        </v-alert>
        <v-card-text>
          <v-form ref="resetForm" v-model="validResetForm" lazy-validation>
            <v-text-field
              v-model="emailToReset"
              outlined
              :rules="[rules.required, rules.email]"
              :label="$t('labels.email')"
              @focus="errorMessage = false"
            />
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="secondary" @click="onSubmitResetForm" text>{{ $t('labels.send_change_pass_link') }}</v-btn>
          <v-btn color="error" text @click="isResetDialog = false">{{ $t('labels.cancel') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isPopMessage" width="300">
      <v-card>
        <v-card-title class="text-h6 grey">{{ $t('labels.message') }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{ popMessage }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="isPopMessage = false" text>{{ $t('labels.ok') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mdiEye, mdiEyeOff, mdiLockReset } from '@mdi/js';
import firebase from 'firebase/app';
import 'firebase/auth';

export default {
  name: 'login',
  data() {
    return {
      valid: true,
      validResetForm: true,
      username: '',
      password: '',
      emailToReset: '',
      querying: false,
      inError: false,
      loading: false,
      rules: {
        required: (value) => !!value || 'Requis.',
        min: (v) => v.length >= 8 || 'Minimum 8 caractères',
        email: (v) => /.+@.+\..+/.test(v) || 'Le courriel doit être valide',
      },
      showPassIcon: mdiEye,
      hidePassIcon: mdiEyeOff,
      resetIcon: mdiLockReset,
      showPass: false,
      isResetDialog: false,
      isPopMessage: false,
      errorMessage: false,
      popMessage: '',
      loginErrorMessage: false,
    };
  },
  methods: {
    login() {
      this.loginErrorMessage = false;
      if (this.$refs.form.validate()) {
        if (this.loading) return;
        this.loading = true;
        firebase
          .auth()
          .signInWithEmailAndPassword(this.username, this.password)
          .then(
            (/* user */) => {
              this.$store.dispatch('organizations/resetState');
              this.$store.dispatch('users/resetState');
              this.$store.dispatch('videos/resetState');
              this.$router.replace('/');
            },
            () => {
              // console.error(err);
              this.loginErrorMessage = 'Courriel ou mot de passe incorrect.';
            },
          )
          .finally((/* */) => {
            this.loading = false;
          });
      }
    },
    checkForSubmission(e) {
      if (e.keyCode === 13) {
        this.login();
      }
    },
    showResetDialog() {
      this.emailToReset = this.username;
      this.isResetDialog = true;
    },
    async onSubmitResetForm() {
      if (this.$refs.resetForm.validate()) {
        firebase
          .auth()
          .sendPasswordResetEmail(this.emailToReset)
          .then(() => {
            this.isPopMessage = true;
            this.isResetDialog = false;
            this.popMessage = `Une message à été envoyé à ${this.emailToReset}.`;
          })
          .catch(() => {
            this.errorMessage = "Ce courriel n'existe pas";
          });
      }
    },
  },
  computed: {
    isSmall() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
  },
  watch: {
    isResetDialog(val) {
      if (!val) {
        this.emailToReset = '';
        this.errorMessage = false;
        this.$refs.resetForm.resetValidation();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-form {
  height: 40vh;
  background-color: lightgray;
  margin-top: 20vh;
}

.form-opacity {
  -khtml-opacity: 0.75;
  -moz-opacity: 0.75;
  -ms-filter: alpha(opacity=75);
  filter: alpha(opacity=75);
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0.75);
  opacity: 0.75;
}

.login-bg {
  background: url('../assets/login_bg.png') no-repeat center center fixed;
  background-size: contain;
  background-color: black;
  height: 100vh;
}
</style>
